/* eslint-disable import/no-extraneous-dependencies */
import React from "react"

import { QueryClient, QueryClientProvider } from "react-query"
import "reflect-metadata"

import DynatraceHead from "@presentation/dynatrace-head"

import { ErrorContext } from "@context/ErrorContext"
import { LoadingContext } from "@context/LoadingContext"

import { Loading } from "./presentation/shared/components/loading/loading"
import { ModalError } from "./presentation/shared/components/modal-error/modal-error"

const queryClient = new QueryClient()

const dynatraceId = process.env.GATSBY_APP_DYNATRACE_JS || ""

const RootElement = (props: any) => (
  <QueryClientProvider client={queryClient}>
    <DynatraceHead id={dynatraceId} />
    <LoadingContext>
      <ErrorContext>
        {props.children}
        <Loading />
        <ModalError />
      </ErrorContext>
    </LoadingContext>
    <ModalError />
  </QueryClientProvider>
)

export { RootElement }
