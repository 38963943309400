import React, { FC, createContext, useState } from "react"

import {
  ControlLoading,
  DestinationType
} from "@context/default/ControlLoading"

type LoadingContextType = {
  control: ControlLoading
  setControlLoading: React.Dispatch<React.SetStateAction<ControlLoading>>
}

const ctxLoading = createContext<LoadingContextType>({
  control: { isLoading: false, destination: DestinationType.NONE },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setControlLoading: () => {}
})

const LoadingContext: FC<any> = ({ children }) => {
  const [control, setControlLoading] = useState<ControlLoading>({
    isLoading: false,
    destination: DestinationType.NONE
  })
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ctxLoading.Provider value={{ control, setControlLoading }}>
      {children}
    </ctxLoading.Provider>
  )
}

export { ctxLoading, LoadingContext }
