/* eslint-disable prefer-destructuring */
import { datalayer } from "@libs/datalayer/datalayer"
import { getPage } from "@libs/utils/urls"
import * as React from "react"
import { VscChromeClose } from "react-icons/vsc"

import LocalStorageRepository from "@infra/repositories/LocalStorageRepository"

import modalErrorDesktop from "@presentation/shared/assets/images/modal-error-desktop.svg"
import modalError from "@presentation/shared/assets/images/modal-error.svg"
import { messageErrors } from "@presentation/shared/components/modal-error/modal-error.contants"
import Modal from "@presentation/shared/components/modal/modal"
import { ctxError } from "@context/ErrorContext"
import Button from "@presentation/shared/components/button/button"

type MessageError = {
  title: string | JSX.Element | null
  key: string | null
  errorLabel: string | null
  description: string | null
  content: JSX.Element
  buttons: {
    mainButton: {
      buttonText: string | JSX.Element | null
      action: any | null
    }
    secondaryButton: {
      buttonText: string | JSX.Element | null
      action: any | null
    } | null
  }
}

export const ModalError = () => {
  const ctxErrorData = React.useContext(ctxError)
  const pathName = typeof window !== "undefined" ? window.location.pathname : ""
  const message: MessageError =
    Object.values(messageErrors).find(
      (element) => element.key === ctxErrorData?.data?.error
    ) || messageErrors.system_default

  const channelYapeMotosExist =
    (pathName.includes("yapemotos") || pathName.includes("bcpmotos")) &&
    ["generic", "default", "not_available", ""].includes(message.key ?? "")

  let namePage = ""

  React.useEffect(() => {
    if (ctxErrorData?.data?.open) {
      const pathname =
        typeof window !== "undefined" ? window.location.pathname : ""

      namePage = getPage(pathname)

      dataLayerClient("Visualizar")
    }
  })

  const dataLayerClient = (action: string) => {
    datalayer.push({
      event: "virtualEvent",
      category: "SOAT Electrónico NEC - Error",
      action: "Sistema",
      label: `${namePage} – Modal – ${ctxErrorData?.data?.type} – ${action}`,
      partner: `${LocalStorageRepository.get("partnerName")}`,
      partnerType: `${LocalStorageRepository.get("partnerType")}`,
      tipoZona: "SOAT",
      zona: "SOAT",
      subZona: namePage,
      seccion: "Modal",
      tipoEvento: action,
      tipoElemento: "Error Negocio",
      valor: ctxErrorData?.data?.type
    })
  }

  const handleClose = () => {
    dataLayerClient("Cerrar")
    ctxErrorData?.data?.eventClose && ctxErrorData?.data?.eventClose()

    if (
      ctxErrorData?.data?.error !== "certificate" &&
      ctxErrorData?.data?.error !== "error-pago"
    )
      ctxErrorData?.data?.event && ctxErrorData?.data?.event()
    ctxErrorData.setData({
      ...ctxErrorData.data,
      open: false
    })
  }

  const handleAccept = () => {
    dataLayerClient("Aceptar")
    ctxErrorData?.data?.event && ctxErrorData?.data?.event()

    ctxErrorData.setData({
      ...ctxErrorData.data,
      open: false
    })
  }

  switch (ctxErrorData?.data?.error) {
    case "error-pago":
      return (
        <Modal
          onClose={handleClose}
          hasClose={false}
          isOpen={ctxErrorData?.data?.open}
          borderRadius={20}
        >
          <div className="text-center w-[340px] sm:w-[400px] p-8 py-16">
            <div
              className="flex items-center  text-sm right-3 top-1 p-3 absolute gap-x-2 cursor-pointer z-10 hover:text-info"
              onClick={handleClose}
              aria-hidden="true"
              data-testid="modal-icon-close"
            >
              <span className="text-[12px] sm:text-[14px]">Cerrar</span>
              <VscChromeClose className="text-[12px] sm:text-[14px]" />
            </div>

            <img
              src="/Seguridad_72x72.svg"
              alt="Icono de tarjeta de credito"
              className="m-auto"
              width={"90px"}
            />
            <h2 className="leading-6 sm:text-xl pb-4 text-info">
              <b>¡Lo sentimos!</b>
              <br />
              {ctxErrorData?.data?.title}
            </h2>
            <p className="leading-6 sm:text-xl pb-8 ">
              {ctxErrorData?.data?.content}
            </p>
            <Button
              className="max-w-[220px] p-2 text-sm "
              onClick={handleClose}
            >
              Aceptar
            </Button>
          </div>
        </Modal>
      )
    case "error-renovation":
      return (
        <Modal
          onClose={handleClose}
          hasClose={false}
          isOpen={ctxErrorData?.data?.open}
          borderRadius={20}
        >
          <div className="text-center w-[340px] sm:w-[400px] p-8 py-16">
            <div
              className="flex items-center  text-sm right-3 top-1 p-3 absolute gap-x-2 cursor-pointer z-10 hover:text-info"
              onClick={handleClose}
              aria-hidden="true"
              data-testid="modal-icon-close"
            >
              <span className="text-[12px] sm:text-[14px]">Cerrar</span>
              <VscChromeClose className="text-[12px] sm:text-[14px]" />
            </div>
            <img
              src="/asistencia-vehicular.svg"
              alt="Icono de Auto en renovación"
              className="m-auto"
              width={"90px"}
            />
            <h2 className="leading-6 sm:text-xl pb-4 text-info">
              <b>¡Lo sentimos!</b>
              <br />
              {ctxErrorData?.data?.title}
            </h2>
            {message.content && (
              <div className="sm:text-lg px-2 pb-7">{message.content}</div>
            )}
            <button
              type="button"
              className="mb-[17px] text-white bg-active  hover:bg-active2 rounded-3xl w-[276px] sm:w-[285px]"
              style={{
                boxShadow:
                  "0px 2px 4px -2px rgba(24, 39, 75, .6), 0px 4px 4px -2px rgba(24, 39, 75, 0.1)"
              }}
              onClick={message.buttons.mainButton.action ?? handleAccept}
              data-testid="modal-button-close"
            >
              {message.buttons?.mainButton?.buttonText ? (
                <span className="font-semibold text-lg sm:text-xl block">
                  {message.buttons?.mainButton?.buttonText}
                </span>
              ) : (
                <span className="py-2.5 font-semibold text-lg sm:text-xl">
                  Aceptar
                </span>
              )}
            </button>
          </div>
        </Modal>
      )
    default:
      return (
        <Modal
          onClose={handleClose}
          hasClose={false}
          isOpen={ctxErrorData?.data?.open}
          borderRadius={20}
        >
          <div className="text-center w-[342px] sm:w-[451px]">
            {!ctxErrorData?.data?.withoutClose && (
              <div
                className="flex items-center right-3 top-1 p-3 absolute gap-x-3 cursor-pointer z-10 hover:text-info"
                onClick={handleClose}
                aria-hidden="true"
                data-testid="modal-icon-close"
              >
                <span>Cerrar</span>
                <VscChromeClose className="text-[25px]" />
              </div>
            )}
            <div>
              <img
                src={modalErrorDesktop as string}
                alt=""
                className="hidden sm:block absolute top-0 w-full pr-3 pt-2"
              />
              <img
                src={modalError as string}
                alt=""
                className="sm:hidden absolute top-0 w-full pr-3 pl-2 opacity-50"
              />
            </div>
            <div className="mx-auto relative pt-[80px] sm:pt-[108px]">
              <p className="text-info text-32fs font-semibold pb-3 ">
                {message.title ?? "Lo sentimos..."}
              </p>

              {ctxErrorData?.data?.title && (
                <p className="text-info text-32fs font-semibold pb-3 ">
                  {ctxErrorData?.data?.title}
                </p>
              )}

              {message.description && (
                <p className="text-2xl sm:text-xl pb-6 mx-auto w-[276px] sm:w-[319px]">
                  {message.description}
                </p>
              )}

              {message.content && !channelYapeMotosExist && (
                <div className="sm:text-lg px-2 pb-7">{message.content}</div>
              )}
              {ctxErrorData?.data?.content && (
                <div className="sm:text-lg px-2 pb-7">
                  <p className="max-w-[300px] sm:max-w-[350px] mx-auto">
                    {ctxErrorData?.data?.content}
                  </p>
                </div>
              )}
              <button
                type="button"
                className="mb-[17px] text-white bg-active  hover:bg-active2 rounded-3xl w-[276px] sm:w-[285px]"
                style={{
                  boxShadow:
                    "0px 2px 4px -2px rgba(24, 39, 75, .6), 0px 4px 4px -2px rgba(24, 39, 75, 0.1)"
                }}
                onClick={message.buttons.mainButton.action ?? handleAccept}
                data-testid="modal-button-close"
              >
                {message.buttons?.mainButton?.buttonText ? (
                  <span className="font-semibold text-lg sm:text-xl block">
                    {message.buttons?.mainButton?.buttonText}
                  </span>
                ) : (
                  <span className="py-2.5 font-semibold text-lg sm:text-xl">
                    Aceptar
                  </span>
                )}
              </button>
              {message.buttons.secondaryButton ? (
                <button
                  type="button"
                  className="py-2.5 mb-7 text-white border-modalbutton border-active rounded-3xl w-[276px] sm:w-[285px]"
                  style={{
                    boxShadow:
                      "0px 2px 4px -2px rgba(24, 39, 75, .6), 0px 4px 4px -2px rgba(24, 39, 75, 0.1)"
                  }}
                  onClick={handleClose}
                >
                  <span className="font-semibold text-lg sm:text-xl text-active">
                    {message.buttons.secondaryButton.buttonText}
                  </span>
                </button>
              ) : (
                <div className="mb-[51px]"></div>
              )}
            </div>
          </div>
        </Modal>
      )
  }
}
