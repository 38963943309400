import React, { createContext, useContext, useState } from "react"

type DataType = {
  open: boolean
  error: string
  event?: any
  type?: string
  title?: string
  eventClose?: any
  withoutClose?: boolean
  content?: React.ReactNode
}
type DataContextType = {
  data: DataType
  setData: (temp: DataType) => void
}

const ctxError = createContext<DataContextType>({} as DataContextType)

const ErrorContext = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<DataType>({
    open: false,
    withoutClose: false,
    error: "generic",
    event: () => { }
  })

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ctxError.Provider value={{ data, setData }}>{children}</ctxError.Provider>
  )
}

const useErrorContext = () => useContext(ctxError)

export { ctxError, ErrorContext, useErrorContext }
